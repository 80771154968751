import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Images
import { SportsTurfServiceImages } from '../imageURLs';

//Data
import keyProcesses from '../../data/sports-turf/key-processes.json';
import listItems from '../../data/sports-turf/tiftuf.json';

import CenterContentNarrow from '../components/CenterContentNarrow';
import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CTAButton from '../components/CTAButton/CTAButton';
import CtaBlock from '../components/CtaBlock';
import CenterContentMain from '../components/CenterContentMain';
import InnerImage from '../components/InnerImage/innerImage';
import FullWidthCarouselSlider from '../components/Carousel/FullWidthCarouselSlider';
import ProcessText from '../components/singleproject/processText'
import TitleSmaller from '../components/TitleSmaller';
import ServiceIntroSection from '../components/ServiceIntroSection';
import ListItemsBlock from '../components/ListItemsBlock';


const featureSliderItems = [
  {
    imagePath: SportsTurfServiceImages.feature1,
  },
  {
    imagePath: SportsTurfServiceImages.feature2,
  },

];

const thumbSliderItems = [
  {
    imagePath: SportsTurfServiceImages.thumb1,
  },
  {
    imagePath: SportsTurfServiceImages.thumb2,
  },
];

const styles = theme =>
  createStyles({

    cardsWrap: {
      marginTop: 30,
      [theme.breakpoints.up(767)]: { 
        flexDirection: "row"
      },
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    imageSection: {
      [theme.breakpoints.up(960)]: {
        padding: '20px 0px 80px 0',
      },
    },

    twoColumnsWrap: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        paddingBottom: 110,
      },
    },

    leftColumnWrap: {
      padding: '40px',
      [theme.breakpoints.up(960)]: {
        flex: '0 0 52%',
        maxWidth: '52%',
        paddingRight: 110,
      },
      [theme.breakpoints.up(1220)]: {
        padding: '40px 135px 40px 0'
      },
    },

    rightColumnWrap: {
      [theme.breakpoints.up(960)]: {
        flex: '0 0 48%',
        maxWidth: '48%',
      },
    },

    rightColumnImage: {
      order:2,
      [theme.breakpoints.up(960)]: {
        order: 0
      },
    },

    sliderWrap: {
      marginTop: 15
    },

  });

const SportsTurfServicePage = ({classes,toggleQuoteComp,children}) => (

<React.Fragment>

  <SEO
    title="Sports Turf - Twin View Turf - Producer of Australia’s Best Lawns"
    keywords={[
      `Turf care guides`,
      `care guides`,
      `Turf`,
      `Lawns`,
      `Best Lawns`,
      `Australia’s Best Lawns`,
      `lawn producer`,
    ]}
  />

    {/* HERO SECTION START */}
    <InnerImage 
      image={{className: classes.img, src: SportsTurfServiceImages.hero}}
    ></InnerImage>

    {/* SERVICES - INTRO SECTION */}
    <ServiceIntroSection
      title="Sports Turf"
      subTitle="For premium playing surfaces."
      text="We are sports turf experts who create high performing playing surfaces for schools, stadiums and high profile sporting venues."
    ></ServiceIntroSection>

    {/* FEATURE SECTION START */}
    <CenterContentMain>
      <Grid
        container
        className={classes.imageSection}
      >
        <FullWidthCarouselSlider largeImage items={featureSliderItems} />
      </Grid>
    </CenterContentMain>

      {/* TWO COLUMNS SECTION START */}
        <CenterContentNarrow>
        <Grid
          container
          direction="column"
          className={classes.twoColumnsWrap}
        >
          {/* left column */}
          <Grid
            container
            direction="column"
            className={classes.leftColumnWrap}
          >
            <TitleSmaller
              title="Sports Turf Specialists"
            ></TitleSmaller>

          {keyProcesses.map(process => (
            <ProcessText
              key={process.title}
              processTitle={process.title}
              processDesc={process.desc}
            />
            ))}

          </Grid>

          {/* right column */}
          <Grid
            container
            direction="column"
            className={classes.rightColumnWrap}
          >
            {/* right column-text */}
            <ListItemsBlock
              title="TifTuf"
              descText="TifTuf has been hand selected out of almost 30,000 different Bermuda varieties, showing superior qualities in drought tolerance, shade tolerance, wear tolerance and winter colour whilst at the same time maintaining excellent turf quality."
              text={listItems}
              ctaText="Learn more"
              ctaHref="/turf-tiftuf"
              inverted
            >
            </ListItemsBlock>

            {/* right column carousel */}
            <FullWidthCarouselSlider mediumImage  items={thumbSliderItems} />
            
          </Grid>
        </Grid>
      </CenterContentNarrow>


    {/* CTA SECTION START */}
    <CtaBlock title="Planning a field construction?">
      <CTAButton
        inverted
        ctaHref="/contact"
        ctaText="Get a quote"
      />
    </CtaBlock>

  {children}
</React.Fragment>

)

export default withQuoteWizard(withStyles(styles, { withTheme: true })(SportsTurfServicePage));