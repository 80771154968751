import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

import { CenterStyleMain } from '../theme';

const styles = (theme) =>
  createStyles({
    centerIt: {
      ...CenterStyleMain,
    },
    childrenBoxesStretching: {
      alignItems: 'stretch',
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start'
      },
    }
  });

const CenterContentMain = ({ classes, doStretch, children, ...rest }) => (
  <Grid container item justify="center" {...rest}
    className={classNames(classes.centerIt, {
      [classes.childrenBoxesStretching]: doStretch
    })}
  >
    {children}
  </Grid>
);

export default withStyles(styles, { withTheme: true })(CenterContentMain);