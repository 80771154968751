import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid,Typography } from '@material-ui/core';
import TitleSmaller from '../components/TitleSmaller'
import CTAButton from '../components/CTAButton/CTAButton';


const styles = theme =>
  createStyles({

    listBlockWrap: {
      backgroundColor: theme.palette.grey.main,
      padding: '40px',
      alignItems: 'flex-start',
      order:1,
      [theme.breakpoints.up(767)]: {
        padding: '40px 50px 40px 50px'
      },
      [theme.breakpoints.up(960)]: {
        order: 0
      },
    },

    listText: {
      fontSize: 20,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      paddingLeft: 10,
      marginBottom: 11,
      fontWeight: 300,
      letterSpacing: '0.5px',
      lineHeight: '22px',
    },
    textWrap: {
      paddingLeft: 25,
      marginTop: 0,
      marginBottom: 5,
      listStyleType: 'square',
    },
    descText: {
      fontSize: 20,
      lineHeight: '22px',
      marginBottom: 20,
      letterSpacing: '0.5px',
    },
    listWrap: {
      maxWidth: 420,
    },
  
  });

const ListItemsBlock = ({
  title,
  descText,
  text,
  ctaText,
  ctaHref,
  inverted,
  classes
}) => {
  const texts = Array.isArray(text) ? text : [text];

  return (

    <Grid
      container
      direction="column"
      className={classes.listBlockWrap}
    >
      {title &&  (
      <TitleSmaller
        title={title}
      ></TitleSmaller>
      )}

      {descText &&  (
      <Typography
        variant="body1"
        color="primary"
        className= {classes.descText}
      > 
        {descText}
      </Typography>
      )}

      <Grid
        container
        className={classes.listWrap}
      >
        {text && (
          <ul className={classes.textWrap}>
            {texts.map((tt, index) => (
              <li
                key={tt}
                className={classNames(classes.listText, {
                [classes.lastText]: index === texts.length - 1,
                })}
              >
                {tt}
              </li>
             ))}
            </ul>
            )}
      </Grid>
        <CTAButton ctaText={ctaText} ctaHref={ctaHref} inverted={inverted} ></CTAButton>
    </Grid>

  );
};

  export default withStyles(styles, { withTheme: true })(ListItemsBlock);